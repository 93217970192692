import { actionUrl } from "@/apiClient";
export const moduleSlug = 'seamless'
export const moduleAuth = 'Seamless';

export const children = [
  {
    path : '',
    component: () => import('./pages/Insights.vue'),
    meta: {
      title: 'Order Insights',
      linkTitle: 'Order Insights',
      linkIcon: 'line-chart',
      showInNav: true,
      permission: 'Seamless.OrderInsights',
    }
  },
  {
    path : 'orders/manage',
    component: () => import('./pages/ordersManage.vue'),
    meta: {
      title: 'Orders Management',
      linkTitle: 'Orders Management',
      linkIcon: 'first-order',
      showInNav: true,
      permission: 'Seamless.OrdersManagement',
    }
  },
  {
    path : 'customer-insights',
    component: () => import('./pages/customerInsights.vue'),
    meta: {
      title: 'Customer Insights',
      linkTitle: 'Customer Insights',
      linkIcon: 'area-chart',
      showInNav: true,
      brandUser: true,
      permission: 'Seamless.CustomerInsights',
    }
  },
  {
    path : 'customer-report',
    component: () => import('./pages/customerReports.vue'),
    meta: {
      title: 'Customer Report',
      linkTitle: 'Customer Report',
      linkIcon: 'file-text-o',
      showInNav: true,
      brandUser: true,
      permission: 'Seamless.CustomerReport',
    }
  },
  {
    path : 'transactions',
    component: () => import('./pages/SeamlessTransaction.vue'),
    meta: {
      title: 'Transactions',
      linkTitle: 'Transactions',
      linkIcon: 'files-o',
      showInNav: true,
      permission: 'Seamless.Transactions',
    }
  },
  {
    path : 'stripe-payments',
    component: () => import('./pages/StripePayments.vue'),
    meta: {
      title: 'Payments',
      linkTitle: 'Payments',
      linkIcon: 'files-o',
      showInNav: true,
      permission: 'Seamless.StripePayments',
    }
  },
  {
    path : 'item-availability',
    component: () => import('./pages/ItemAvailability.vue'),
    meta: {
      title: 'Item availability',
      linkTitle: 'Item availability',
      linkIcon: 'files-o',
      showInNav: false,
      permission: 'Seamless.Transactions',
    }
  },
  {
    path: 'config',
    component: () => import('@/components/Configuration/Configurations.vue'),
    props: { moduleSlug: moduleSlug },
    meta: {
      title: 'Configurations',
      linkIcon: '',
      showInNav: false,
      brandFlag: 'enableConfigurations',
      permission: 'Seamless.OrderInsights',
    }
  }
];
export const quickLinks = [
  {
    name:'Catalogue',
    link: actionUrl + 'merchant/:id/catalogue/new',
    permission: 'Configurations.Catalogue',
  },
  {
    name :'Timing',
    link: actionUrl + 'merchant/:id/store-timings',
    permission: 'Configurations.Profile',
  },
  {
    name :'Variants',
    link: actionUrl + 'merchant/:id/catalogue/variants',
    permission: 'Configurations.Catalogue',
  },
  {
    name: 'Item Availability',
    link: `/${moduleSlug}/item-availability`,
    new: true,
    permission: 'Seamless',
  },
  {
    name: 'Configurations',
    link: `/${moduleSlug}/config`,
    new: true,
    brandFlag: 'enableConfigurations',
    permission: "Seamless.OrderInsights",
  },
];