import crypto from 'crypto-js'
import axios from 'axios'

const client_id = import.meta.env.VUE_APP_OAUTH_CLIENT_ID
const oauthServer = import.meta.env.VUE_APP_OAUTH_SERVER_URL

const getAccessToken = () => {
    return localStorage.getItem('access_token')
}

const setAccessToken = (token) => {
    localStorage.setItem('access_token', token)
}

const getRefreshToken = () => {
    return localStorage.getItem('refresh_token')
}

const setRefreshToken = (token) => {
    localStorage.setItem('refresh_token', token)
}

const clearTokens = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
}

const generateRandomString = (length) => {
    return [...Array(length)].map(() => Math.random().toString(36)[2]).join('')
}
const base64UrlEncode = (str) => {
    return str.toString(crypto.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
}

const initiateOAuth = () => {
    const codeVerifier = generateRandomString(128)
    const codeChallenge = base64UrlEncode(crypto.SHA256(codeVerifier))

    // Store code verifier for later use
    localStorage.setItem('code_verifier', codeVerifier)

    const oauthUrl = `${oauthServer}/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${window.location.origin}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=*`
    // Store the current URL before redirecting for later use
    localStorage.setItem('original_url', window.location.href)
    window.location.href = oauthUrl
}
const handleTokenExchange = async (code) => {
    const codeVerifier = localStorage.getItem('code_verifier')
    localStorage.removeItem('code_verifier')
    try {
        const response = await axios.post(`${oauthServer}/oauth/token`, {
            grant_type: 'authorization_code',
            client_id,
            redirect_uri: `${window.location.origin}`,
            code,
            code_verifier: codeVerifier,
        })

        const { access_token, refresh_token } = response.data
        setAccessToken(access_token)
        setRefreshToken(refresh_token)

        // Redirect back to the original page
        const originalUrl = localStorage.getItem('original_url') || '/'
        window.location.href = originalUrl
    } catch (error) {
        console.error('Error exchanging code for token:', error)
        window.location.href = '/'
    }
}

const updateTokens = async () => {
    try {
        const refreshToken = getRefreshToken()
        if(!refreshToken){
            throw new Error('No refresh Token found')
        }
        const response = await axios.post(`${oauthServer}/oauth/token`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id,
        })


        const { access_token, refresh_token } = response.data
        setAccessToken(access_token)
        setRefreshToken(refresh_token)
        return access_token;

    } catch (error) {
        console.error(error.message)
        clearTokens()
        return null
    }
}

export {
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
    clearTokens,
    updateTokens,
    initiateOAuth,
    handleTokenExchange
}