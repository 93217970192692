import { defineStore } from "pinia";
import { client } from "@/apiClient";
import { urls } from "../modules/Referral/urls";

export const useReferralProgramsStore = defineStore("referralPrograms", {
  state: () => ({
    programsList: [],
    selectedProgram: {},
    selectedProgramId: '',
    referralLoading: true,
  }),

  actions: {
    async fetchProgramsList(brandId) {
      this.referralLoading = true;
      const { data } = await client.post(urls.getReferralProgramsUrl,{
          brandId: brandId,
      })
      this.referralLoading = false;
      const programsList  = data.programs ?? [];
      const selectedProgram = programsList ? programsList[0] : {};
      const selectedProgramId = selectedProgram ? selectedProgram.id : '';
      this.$patch({
        programsList,
        selectedProgram,
        selectedProgramId
      });
    },
    selectReferrelProgram(program) {
      this.selectedProgram = program;
      this.selectedProgramId = program.id;
    },
  },
});
