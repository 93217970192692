<template>
  <div style="max-width: 250px !important">
    <fr-dropdown v-if="giftingList.length">
      <fr-dropdown-heading
        auto-caret
        auto-close="outside"
        class="rounded d-flex align-items-center text-white border-none p-2"
        style="max-width: 250px !important"
      >
        <div
          class="text-start pe-2"
          style="max-width: 90% !important; min-height: 20px; cursor: pointer"
        >
          {{ selectedGifting?.name }}
        </div>
      </fr-dropdown-heading>

      <fr-dropdown-group>
        <fr-dropdown-options
          v-for="gifting in giftingList"
          :key="gifting.id"
          class="giftingClass"
          :class="gifting.id == selectedGifting.id ? 'bg-purple' : ''"
          @click="selectGifting(gifting)"
        >
          {{ gifting.name }}
        </fr-dropdown-options>
      </fr-dropdown-group>
    </fr-dropdown>
  </div>
</template>
<script>
import {
  FrDropdown,
  FrDropdownHeading,
  FrDropdownGroup,
  FrDropdownOptions
} from "@froogal.ai/ui";
import {useGiftingStore} from "@/stores/gifting";
import {storeToRefs} from "pinia";
import {useBrandsStore} from "@/stores/brands";
export default {
  name: "loyalty-picker",
  setup() {
    const giftings = useGiftingStore();
    const brands = useBrandsStore();
    const {selectedBrandId} = storeToRefs(brands);
    const {giftingList, selectedGifting} = storeToRefs(giftings);
    const {selectGifting, fetchGiftingList} = giftings;
    return {
      selectedBrandId,
      selectedGifting,
      giftingList,
      selectGifting,
      fetchGiftingList
    };
  },
  components: {
    FrDropdown,
    FrDropdownGroup,
    FrDropdownHeading,
    FrDropdownOptions
  },
  mounted() {
    if (this.selectedBrandId) {
      this.fetchGiftingList(this.selectedBrandId);
    }
  },
  watch: {
    selectedBrandId() {
      if (this.selectedBrandId) {
        this.fetchGiftingList(this.selectedBrandId);
      }
    }
  }
};
</script>
<style>
.giftingClass:active {
  background-color: #59348b;
}
</style>
