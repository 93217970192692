export const moduleSlug = 'offers';
export const moduleAuth = 'Offers';
import LoyaltyPicker from "../Loyalty/components/LoyaltyPicker.vue";
export const children = [
    {
        path: "",
        component: () => import('./pages/offerManager/OfferManager.vue'),
        meta: {
            title: 'Offer Manager',
            linkTitle: 'Offer Manager',
            linkIcon: 'gift',
            showInNav: true,
            permission: 'Offers.OfferManager',
        }
    },
    {
        path: "offers-report",
        component: () => import('./pages/OffersReport.vue'),
        meta: {
            title: 'Offer Report',
            linkTitle: 'Offer List',
            linkIcon: 'line-chart',
            showInNav: true,
            permission: 'Offers.OfferList',
        }
    },
    {
        path: "coupon-report",
        component: () => import('../Loyalty/pages/LoyaltyCouponReport.vue'),
        meta: {
            title: 'Coupon Report',
            linkTitle: 'Coupon Report',
            linkIcon: 'pie-chart',
            showInNav: true,
            permission: 'Offers.CouponReport',
        }
    },
    {
        path: 'config/third-party-rewards',
        component: () => import('./pages/LoyaltyThirdParty.vue'),
        meta: {
            title: "Vouchers",
            linkIcon: 'credit-card',
            showInNav: false,
            permission: 'Offers.ThirdPartyRewards',
        }
    },
    {
        path: 'create',
        component: () => import('./components/offerCreation/OfferCreation.vue'),
        meta: {
            title: 'Offer-Engine',
            showInNav: false,
            permission: 'Offers.OfferManager',
        }
    },
    {
        path: 'edit/:id',
        component: () => import('./components/offerCreation/OfferCreation.vue'),
        meta: {
            title: 'Offer-Engine',
            showInNav: false,
            permission: 'Offers.OfferManager',
        }
    }
];
export const quickLinks = [
    {
        name: 'Third Party Rewards',
        link: '/' + moduleSlug + '/config/third-party-rewards',
        new: true,
        permission: 'Offers.ThirdPartyRewards',
      }
];

export const picker = LoyaltyPicker;