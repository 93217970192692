<template>
  <div>
    <fr-dropdown class="pe-2">
      <fr-dropdown-heading
        auto-caret
        auto-close="outside"
        class="rounded d-flex align-items-center text-white border-none p-2"
        style="max-width: 160px !important"
      >
        <div class="text-start text-truncate" style="max-width: 90% !important; min-height: 20px;cursor: pointer;">

          {{ selectedLoyalty?.name }}
        </div>
      </fr-dropdown-heading>

      <fr-dropdown-group>
        <fr-dropdown-options
          v-for="loyalty in loyalties"
          :key="loyalty.id"
          class="loyaltyClass"
          :class="loyalty.id == selectedLoyalty.id ? 'bg-purple' : ''"
          @click="selectLoyalty(loyalty)"
        >
          {{ loyalty.name }}
        </fr-dropdown-options>
      </fr-dropdown-group>
    </fr-dropdown>
  </div>
</template>
<script>
import {
  FrDropdown,
  FrDropdownHeading,
  FrDropdownGroup,
  FrDropdownOptions,
} from "@froogal.ai/ui";
import { useLoyaltyStore } from "@/stores/loyalties";
import { storeToRefs } from "pinia";
import { useBrandsStore } from "@/stores/brands";
export default {
  name: "loyalty-picker",
  setup() {
    const loyaltiesList = useLoyaltyStore();
    const brands = useBrandsStore();
    const { selectedBrandId } = storeToRefs(brands);
    const { loyalties, selectedLoyalty } = storeToRefs(loyaltiesList);
    const { fetchLoyalties, selectLoyalty } = loyaltiesList;
    return {
      loyalties,
      selectedLoyalty,
      fetchLoyalties,
      selectedBrandId,
      selectLoyalty,
    };
  },
  components: {
    FrDropdown,
    FrDropdownGroup,
    FrDropdownHeading,
    FrDropdownOptions,
  },
  mounted() {
    if (this.selectedBrandId ) {
      this.fetchLoyalties(this.selectedBrandId);
    }
  },
  watch: {
    selectedBrandId() {
      this.fetchLoyalties(this.selectedBrandId);
    },
  },
};
</script>
<style>
.loyaltyClass:active {
  background-color: #59348b;
}
</style>
