export const urls = {
    getReferralProgramsUrl: "referral/brand-programs",

    // dashboard
    getDashboardInsightsUrl: "referral/brand-dashboard",
    getConversionGraphDataUrl: "referral/conversion-graph",
    getReferringGraphDataUrl: "referral/referring-graph",
    getJoinedReferrersDataUrl: "referral/joined-referrer-graph",

    // programs
    getProgramsListUrl: "referral/program-list",
    changeStatusUrl: "referral/toggle-enable",

    // program Analytics
    getProgramsAnalyticsInsightsurl: "referral/program-analytics",
    getProgramReferrerGraphUrl: "referral/program-referrer-graph",
    getProgramConversionGraphUrl:"referral/program-conversion-graph",
    // referrsReports
    getReferringsDataUrl:"referral/referrers-reports",
    getReferModelUrl:"referral/referrer-data",
    // milestoneReport
    getMilestoneDataUrl:"referral/milestone-reports",
    getMileModelUrl:"referral/referrer-data",
     //GoalwiseReport
    getGoalwiseDataUrl:"referral/goal-reports",
    getGoalswiseEventUrl:"referral/program-goals",
    getReferrerGoalDataUrl: "referral/referrer-goal-data",
    getProgramConversionGraphUrl: "referral/program-conversion-graph",

    // goal wise analytics
    getProgramGoalsDataUrl: "referral/program-goals",
    getGoalAnalyticsUrl: "referral/goal-analytics",
    getGoalReferrerGraphUrl: "referral/goal-referrer-graph",
    getGoalRefereeGraphUrl: "referral/goal-referee-graph",

    // program reports
    getProgramReportsUrl: "referral/program-reports",
    getReferrerData: "referral/referrer-data",
    
    
    getbrandWiseFreebiesUrl:"referral/brand-wise-freebies",
    programCreateUrl: "referral/program/create",
    goalCreateurl: "referral/goal/create",
    mainGoalCreateUrl: "referral/program/maingoal-create",
};