export const urls = {
    getManagersList: (brandId) =>           `brands/${brandId}/managers`,
    getDevicesList: (brandId) =>            `brands/${brandId}/devices`,
    getRolesList: (brandId) =>              `brands/${brandId}/roles`,
    getPermissionsList: (brandId) =>        `brands/${brandId}/permissions`,
    getMerchantsList: (brandId) =>          `brands/${brandId}/merchants`,
    getInvitesList: (brandId) =>            `brands/${brandId}/invites`,

    postRole: (brandId) =>                  `brands/${brandId}/roles`,
    putRole: (brandId, roleId) =>           `brands/${brandId}/roles/${roleId}`,
    deleteRole: (brandId, roleId) =>        `brands/${brandId}/roles/${roleId}`,

    postCheckDevice: (brandId) =>           `brands/${brandId}/devices/new`,
    postVerifyDevice: (brandId) =>          `brands/${brandId}/devices/verify`,
    postCreateDevice: (brandId) =>          `brands/${brandId}/devices`,
    putDevice: (brandId, deviceId) =>       `brands/${brandId}/devices/${deviceId}`,
    deleteDevice: (brandId, deviceId) =>    `brands/${brandId}/devices/${deviceId}`,

    putManager: (brandId, managerId) =>     `brands/${brandId}/managers/${managerId}`,
    deleteManager: (brandId, managerId) =>  `brands/${brandId}/managers/${managerId}`,

    postCreateInvite: (brandId) =>          `brands/${brandId}/invites`,
    postUpdateInvite: (brandId) =>          `brands/${brandId}/invites`,
    deleteInvite: (brandId, token) =>       `brands/${brandId}/invites/${token}`,

    getProfile:                             `profile`,
    postProfile:                            `profile`,
    postProfilePassword:                    `profile/update-password`,

    getProfilePermissions: (brandId) =>     `profile/brands/${brandId}/permissions`,
    getProfileBrands:                       `profile/brands`,
    deleteProfileBrands: (brandId) =>       `profile/brands/${brandId}`,
};