export const moduleSlug = 'gifting';
export const moduleAuth = 'Gifting';
import GiftingPicker from "./components/GiftingPicker.vue"
export const picker = GiftingPicker;
export const children = [
  {
    path: "",
    component: () => import('./pages/RedeemptionPanel.vue'),
    meta: {
      title: 'Dashboard',
      linkTitle: 'Dashboard',
      linkIcon: 'home',
      showInNav: true,
      permission: 'Gifting.Redemption',
    }
  },
  {
    path: "",
    meta: {
      title: "Configurations",
      linkTitle: "Congigurations",
      linkIcon: "sliders",
      showInNav: true,
      excludeInRoute: true,
      menu: [
        {
          path: "configuration/denominations",
          title: "Denomination",
          permission: 'Gifting.Configurations.Denominations',
        },
        {
          path: "configuration/logo",
          title: "Logo",
          permission: 'Gifting.Configurations.Logo',
        },
        {
          path: "configuration/terms-and-instructions",
          title: "Terms And Instructions",
          permission: 'Gifting.Configurations.Terms',
        },
        {
          path: "configuration/category",
          title: "Category",
          permission: 'Gifting.Configurations.Category',
        },
        {
          path: "configuration/themes",
          title: "Themes",
          permission: 'Gifting.Configurations.Themes',
        },
        {
          path: "configuration/offer",
          title: "Offer",
          permission: 'Gifting.Configurations.Offer',
        },
      ],
    },
  },
  {
    path: "",
    meta: {
      title: "Reports",
      linkTitle: "Report",
      linkIcon: "bar-chart",
      showInNav: true,
      excludeInRoute: true,
      menu: [
        {
          path: "reports/orders",
          title: "Orders",
          permission: 'Gifting.Orders',
        },
        {
          path: "reports/transcations",
          title: "Transactions",
          permission: 'Gifting.Transactions',
        },
        {
          path: "reports/gift-cards",
          title: "Gift Cards",
          permission: 'Gifting.GiftCards',
        },
      ],
    },
  },
 
  {
    path: "configuration/denominations",
    component: () => import('./pages/Denomination.vue'),
    meta: {
      title: 'Denomination',
      linkTitle: 'Denomination',
      showInNav: false,
      permission: 'Gifting.Configurations.Denominations',
    }
  },
  {
    path: "configuration/logo",
    component: () => import('./pages/LogoConfiguration.vue'),
    meta: {
      title: 'Logo',
      linkTitle: 'Logo',
      showInNav: false,
      permission: 'Gifting.Configurations.Logo',
    }
  },
  {
    path: "configuration/category",
    component: () => import('./pages/Category.vue'),
    meta: {
      title: 'Category',
      linkTitle: 'Category',
      showInNav: false,
      permission: 'Gifting.Configurations.Category',
    }
  },
  {
    path: "configuration/themes",
    component: () => import('./pages/Themes.vue'),
    meta: {
      title: 'Themes',
      linkTitle: 'Themes',
      showInNav: false,
      permission: 'Gifting.Configurations.Themes',
    }
  },
  {
    path: "configuration/offer",
    component: () => import('./pages/Offer.vue'),
    meta: {
      title: 'Offer',
      linkTitle: 'Offer',
      showInNav: false,
      permission: 'Gifting.Configurations.Offer',
    }
  },
  {
    path: "configuration/terms-and-instructions",
    component: () => import('./pages/TermsAndInstructions.vue'),
    meta: {
      title: 'Terms And Instructions',
      linkTitle: 'Terms And Instructions',
      showInNav: false,
      permission: 'Gifting.Configurations.Terms',
    }
  },
  {
    path: "reports/orders",
    component: () => import('./pages/Orders.vue'),
    meta: {
      title: 'Orders',
      linkTitle: 'Orders',
      showInNav: false,
      permission: 'Gifting.Orders',
    }
  },
  {
    path: "reports/transcations",
    component: () => import('./pages/transactionhistory.vue'),
    meta: {
      title: 'Transaction',
      linkTitle: 'Transaction',
      showInNav: false,
      permission: 'Gifting.Transactions',
    }
  },
  {
    path: "reports/gift-cards",
    component: () => import('./pages/giftcard.vue'),
    meta: {
      title: 'Giftcard',
      linkTitle: 'Giftcard',
      showInNav: false,
      permission: 'Gifting.GiftCards',
    }
  },
  {
    path: "configuration/setup",
    component: () => import('./pages/GiftingConfiguration.vue'),
    meta: {
      title: 'Gifting Configuration',
      linkTitle: 'Gifting Configuration',
      showInNav: false,
      isAdmin: true,
      permission: 'Gifting.Configurations',
    }
  },
];