import { defineStore } from "pinia";
import { client, urls, RBACclient } from "@/apiClient";
import { useBrandsStore } from "./brands";
import { urls as iamUrls } from "../modules/UserManagement/urls";

export const usePermissionStore = defineStore("permissions", {
  state: () => ({
    permissions: null,
    permissionLoader: true,
    isBrandAdmin: false
  }),
  actions: {
    async fetchPermissions() {
      this.permissionLoader = true;
      try {
        const brands = useBrandsStore();
        if (brands.selectedBrand?.flags.enableUserManagement) {
          const { data } = await RBACclient.get(iamUrls.getProfilePermissions(brands.selectedBrandId));
          this.permissions = data.permissions;
          this.isBrandAdmin = data.isBrandAdmin;
        } else {
          const { data } = await client.get(urls.permissions, { params: { brandId: brands.selectedBrandId },});
          this.permissions = data.permissions;
          this.isBrandAdmin = data.isBrandAdmin;
        }
        this.permissionLoader = false;
        const { permissions, isBrandAdmin } = data;
        this.$patch({
          permissions,
          isBrandAdmin
        });
      } catch(e){
      }
    },
    checkAuthorization(newSlug, oldSlug = "") {
      const brands = useBrandsStore();
      if (brands.selectedBrand?.flags.enableUserManagement) {
        return this.permissions?.includes(newSlug);
      } else {
        return oldSlug ? this.permissions?.includes(oldSlug) : true;
      }
    },
  },
});
