<template>
  <fr-portlet class="d-flex flex-column align-items-center justify-content-center p-5" style="height: calc(100vh - 150px)">
    <img src="../assets/error-404.png" class="img-responsive" style="max-height: 350px;" />
    <br>
    <h4><strong>Looks like you've wandered off to nowhere</strong></h4>
    <a class="back-link" href="javascript:history.back()"><strong>Click here to go back</strong></a>
  </fr-portlet>
</template>

<script>
import {
  FrPortlet,
} from "@froogal.ai/ui";
export default {
  components: {
    FrPortlet,
  }
}
</script>

<style scoped>
.back-link {
  color: var(--brand-purple);
  text-decoration: none;
}
</style>