<template>
  <div class="confirm-dialog" v-if="show">

    <div class="bg-white p-3  rounded-3" style="max-width: 400px; min-width: 300px">
      <div class="lh-sm text-start" style="font-size: 18px;">
        <div v-html="message"></div>
      </div>
      <div class="mt-3 pt-2 d-flex justify-content-end border-top">
        <button
          class="btn me-1 w-25 btn-sm"
          @click="Confirm(false)"
          :class="cancel.class || 'btn-outline-secondary'"
        >
          {{ cancel.label || 'Cancel' }}
        </button>
        <button
          class="btn w-25 btn-sm"
          :class="confirm.class || 'btn-danger'"
          @click="Confirm(true)"
        >
          {{ confirm.label || 'Confirm' }}
        </button>
      </div>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    message: {
      type: String,
      required: true
    },
    confirm: {
      type: Object,
      default: () => { return {} }
    },
    cancel: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    this.show = true;
  },
  methods: {
    Confirm(val) {
      this.$emit('confirm', val);
      this.show = false
    },
  },
};
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right:0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* user-select: none; */
  overflow: hidden;

}
</style>