<template>
  <div class="row justify-content-center">
    <div class="col-3">

      <fr-portlet class="mx-0">
        <fr-portlet-header>
          <fr-portlet-heading>Update details</fr-portlet-heading>
        </fr-portlet-header>
        <fr-portlet-item>
          <form ref="userForm" class="form-horizontal">
            <label>Name</label>
            <input v-model="user.name" class="form-control" required>
            <label class="pt-3">Phone number</label>
            <input v-model="user.phoneNumber" class="form-control" required>
            <div class="pt-3 d-grid">
              <button type="button" @click="validateAndUpdateUserProfile()" class="btn btn-primary">Update</button>
            </div>
          </form>
        </fr-portlet-item>
      </fr-portlet>

      <fr-portlet class="mx-0">
        <fr-portlet-header>
          <fr-portlet-heading>Password change</fr-portlet-heading>
        </fr-portlet-header>
        <fr-portlet-item>
          <form ref="passwordForm" class="form-horizontal">
            <label>Current password</label>
            <input type="password" class="form-control" v-model="selectedPasswords.oldPassword" required />
            <label class="pt-3">New Password</label>
            <input type="password" class="form-control" v-model="selectedPasswords.newPassword" required />
            <label class="pt-3">Confirm New Password</label>
            <input type="password" class="form-control" v-model="selectedPasswords.confirmPassword" required />
            <div class="pt-3 d-grid">
              <button type="button" @click="validateAndUpdatePassword()" class="btn btn-primary">Submit</button>
            </div>
            <!-- TODO: IMPLEMENT FORGOT PASSWORD -->
          </form>
        </fr-portlet-item>
      </fr-portlet>

    </div>
    <div class="col-4  ps-0">

      <fr-portlet class="mx-0">
        <fr-portlet-header bordered>
          <fr-portlet-heading>Leave brands</fr-portlet-heading>
        </fr-portlet-header>
        <fr-portlet-item>
          <div class="row">
            <div v-for="brand in brands" :key="brand.id" class="col-md-6 mb-2 px-1 d-grid">
              <div class="card">
                <div class="card-body p-2">
                  <div class="d-flex" style="align-items: center;">
                    <img :src="brand.logo" class="w-25" style="aspect-ratio: 1;" />
                    <h6 class="card-title mb-0 ps-2">{{ brand.name }}</h6>
                  </div>
                  <!-- <button disabled class="btn btn-outline-success btn-sm" v-if="brand.pivot.brand_admin">
                    <li class="fa fa-id-badge" /> Brand Admin
                  </button> -->
                  <!-- ABOVE CODE ONLY WORKS IF BRANDS.JS PULLS FROM USER MANAGEMENT SERVER -->
                </div>
                <button class="btn btn-danger btn-sm" @click="deleteBrandModal(brand.id)" disabled><i
                    class="fa fa-chain-broken"></i></button>
              </div>
            </div>
          </div>
        </fr-portlet-item>
      </fr-portlet>

      <!-- <fr-portlet class="mx-0 d-grid">
        <button type="button" class="btn btn-danger" @click="deleteAccountModal()">Delete my account</button>
      </fr-portlet> -->

    </div>
  </div>

  <fr-modal :value="showDeleteBrandModal" :showHeader="false"
    @ok="deassignManager(selectedBrandId), showDeleteBrandModal = false, selectedBrandId = null" okText="Leave"
    @cancel="showDeleteBrandModal = false, selectedBrandId = null"
    @hidden="showDeleteBrandModal = false, selectedBrandId = null">
    Are you sure you want to leave {{ tempBrandName }}? You will have to be re-invited to be added back. You will lose all
    of your current merchant and role configurations.
  </fr-modal>

  <!-- <fr-modal :value="showDeleteAccountModal" :showHeader="false" @ok="deleteUserProfile(), showDeleteAccountModal = false"
    okText="Delete my account" @cancel="showDeleteAccountModal = false" @hidden="showDeleteAccountModal = false">
    Are you sure you want to permanently delete your account? You will have to be re-invited to be added back. You will
    lose access to all of your current brand associations, merchants, and roles.
  </fr-modal> -->
</template>
<script>
import {
  FrModal,
  FrPortlet,
  FrPortletHeader,
  FrPortletHeading,
  FrPortletItem
} from "@froogal.ai/ui";
import { useUserProfileStore } from '../stores/user-profile';
import { useBrandsStore } from "../stores/brands";
import { storeToRefs } from "pinia";
export default {
  setup() {
    const profileStore = useUserProfileStore();
    const brandStore = useBrandsStore();
    profileStore.fetchUserProfile();
    const { user } = storeToRefs(profileStore);
    const { brands } = storeToRefs(brandStore);
    const { updateUserProfile, updatePassword } = profileStore;
    const { deassignManager } = brandStore;
    return {
      user,
      brands,
      updateUserProfile,
      updatePassword,
      deassignManager,
      // deleteUserProfile,
    }
  },
  components: {
    FrModal,
    FrPortlet,
    FrPortletHeader,
    FrPortletHeading,
    FrPortletItem
  },
  data: function () {
    return {
      selectedPasswords: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      selectedBrandId: null,
      tempBrandName: "",
      showDeleteBrandModal: false,
      showDeleteAccountModal: false,
    }
  },
  methods: {
    validateAndUpdateUserProfile() {
      if (this.$refs.userForm.reportValidity()) this.updateUserProfile();
    },
    validateAndUpdatePassword() {
      if (this.$refs.passwordForm.reportValidity()) {
        this.updatePassword(this.selectedPasswords);
        this.selectedPasswords = {
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        };
      }
    },

    deleteBrandModal(id) {
      this.selectedBrandId = id;
      this.tempBrandName = this.brands.find(b => b.id === id).name;
      this.showDeleteBrandModal = true;
    },
    deleteAccountModal() {
      this.showDeleteAccountModal = true;
    }
  }
};
</script>