export const moduleSlug = "wallet";
export const moduleAuth = "Wallet";

export const children = [
  {
    path: "",
    component: () => import("./pages/Customers.vue"),
    meta: {
      title: "Customers",
      linkTitle: "Customers",
      linkIcon: "list",
      showInNav: true,
      permission: "Wallet.Customers",
    },
  },
  {
    path: "transactions",
    component: () => import("./pages/Transactions.vue"),
    meta: {
      title: "Transactions",
      linkTitle: "Transactions",
      linkIcon: "list",
      showInNav: true,
      permission: "Wallet.Transactions",
    },
  },
];
