export const moduleSlug = 'imports';
export const moduleAuth = 'Imports';
export const children = [
  {
    path: "",
    component: () => import('./pages/Transactions.vue'),
    meta: {
      title: 'Import Transactions',
      linkTitle: 'Transactions',
      linkIcon: 'credit-card',
      showInNav: true,
      permission: 'Imports.Read',
    }
  },
];