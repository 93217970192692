<template>
  <fr-base-container>
    <fr-header style="z-index: 10" :class="negitiveHeight ? 'fade-in-bottom' : ''">
      <template v-slot:module>
        <router-view name="module"></router-view>
      </template>
      <template v-slot:brand>
        <router-view name="brand"></router-view>
      </template>
    </fr-header>
    <div v-if="!brandsLoading">
      <router-view v-if="$route.path == '/profile-settings'"></router-view>
      <router-view v-else-if="$route.params.brand && selectedBrand" :class="negitiveHeight ? 'fade-in-bottom-second' : ''"></router-view>
      <div v-else class="main-container d-flex flex-column align-items-center justify-content-center p-5 fw-bolder">
        <h3 class="pb-3">Select a brand to continue</h3>
        <div style="min-width: 550px;">
          <brand-selector class="shadow" :selected-brand="selectedBrand" :height="'height:300px'"></brand-selector>
        </div>
      </div>
    </div>
  </fr-base-container>
</template>

<script>
import BrandSelector from "../components/BrandSelector.vue";
import Error404Page from "../views/Error404Page.vue";
import { FrHeader, FrBaseContainer, FrHeaderNav, FrHeaderNavItem } from "@froogal.ai/ui";
import { useUserProfileStore } from "@/stores/user-profile";
import { useBrandsStore } from "@/stores/brands";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const brandStore = useBrandsStore();
    const { setBrandUri } = brandStore;
    const profileStore = useUserProfileStore();
    const { user } = storeToRefs(profileStore);
    const { selectedBrand, selectedBrandUri, brandsLoading } = storeToRefs(brandStore);
    return {
      setBrandUri,
      selectedBrand,
      selectedBrandUri,
      user,
      brandsLoading,
    };
  },
  components: {
    FrBaseContainer,
    FrHeaderNav,
    FrHeaderNavItem,
    FrHeader,
    BrandSelector,
    Error404Page,
  },
  props: ["selectedModule", "subNavigation", "quickLinks"],
  data() {
    return {
      height: 0,
      afterHeight: 0,
      negitiveHeight: false,
    }
  },
  created() {
    window.addEventListener("scroll", this.scrollFunction);
    this.setBrandUri(this.$route.params.brand);
    this.$watch(
      () => this.$route.params.brand,
      (toParams, previousParams) => {
        this.setBrandUri(toParams);
      }
    )
  },
  watch: {
    selectedBrandUri() {
      if (this.selectedBrandUri !== this.$route.params.brand) {
        // selectedBrandUri is set to null if there are multiple brands, in this scenario
        this.$router.push({ params: { brand: this.selectedBrandUri } });
      }
    }
  },
  methods: {
    scrollFunction() {
      this.afterHeight = document.documentElement.scrollTop;
      if (this.afterHeight > this.height) {
        this.height = this.afterHeight;
        this.negitiveHeight = false;
      } else if (this.afterHeight - this.height <= -10) {
        this.negitiveHeight = true;
        this.height = this.afterHeight;
      }
    },
  },
};
</script>