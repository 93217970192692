import {actionUrl} from "@/apiClient";

export const moduleSlug = 'url-shortener';
export const moduleAuth = 'Loyalty';
export const children = [
    {
        path: '',
        component: () => import('./pages/UrlShortner.vue'),
        meta: {
            title: 'Url-Shortener Dashboard',
            linkTitle: 'Dashboard',
            linkIcon: 'home',
            showInNav: true,
            permission: "UrlShortener.Read",
        }
    },
    {
        path: 'url/create',
        component: () => import('./pages/CreateShortUrl.vue'),
        meta: {
            title: 'Url Creation',
            showInNav: false,
            permission: "UrlShortener.Create",
        }
    },
];
