<template>
  <div class="p-2 bg-white mx-2 rounded-4 text-start">
    <div class="text-center w-100 p-1">
      <div class="fw-bolder">{{ profile.name }}</div>
      <div style="font-size: 14px" class="bg-white rounded p-1">
        {{ profile.email }}
      </div>
      <div style="font-size: 14px" class="bg-white rounded p-1">
        {{ profile.phoneNumber }}
      </div>
    </div>
    <hr class="m-1" />
    <div data-v-01b96e43="" class="text-center fw-bolder w-100">
      <router-link to="/profile-settings" class="btn btn-sm fw-bolder">
        Profile Settings
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: ["profile"],
};
</script>
