import { getAccessToken, updateTokens, initiateOAuth } from './authService'
import { Mutex } from 'async-mutex'

const clientMutex = new Mutex();
export default {
    setupInterceptors(client) {
        client.interceptors.request.use(
            async (config) => {
                await clientMutex.waitForUnlock(); // wait until the mutex is available without locking it
                const accessToken = getAccessToken();
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        client.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalRequest = error.config;

                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;

                    if (!clientMutex.isLocked()) {
                        const release = await clientMutex.acquire();
                        try {
                            const accessToken = await updateTokens();
                            if (accessToken) {
                                // Retry the original request with the new access token
                                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                                return client(originalRequest);
                            } else {
                                return initiateOAuth();
                            }
                        } finally {
                            release(); // Release the mutex
                        }
                    } else {
                        await clientMutex.waitForUnlock()
                        return client(originalRequest);
                    }
                }
                return Promise.reject(error);
            }
        );
    }
};