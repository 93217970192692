<template>
    <div style="max-width: 250px !important">
      <fr-dropdown class="">
        <fr-dropdown-heading
          auto-caret
          auto-close="outside"
          class="rounded d-flex align-items-center text-white border-none p-2"
          style="max-width: 250px !important"
        >
          <div class="text-start pe-2" style="max-width: 90% !important; min-height: 20px;cursor: pointer">
            {{ selectedProgram?.name }}
          </div>
        </fr-dropdown-heading>
  
        <fr-dropdown-group>
          <fr-dropdown-options
            v-for="program in programsList"
            :key="program.id"
            class="referralClass"
            :class="program.id == selectedProgramId ? 'bg-purple' : ''"
            @click="selectReferrelProgram(program)"
          >
            {{ program.name }}
          </fr-dropdown-options>
        </fr-dropdown-group>
      </fr-dropdown>
    </div>
  </template>
  <script>
  import {
    FrDropdown,
    FrDropdownHeading,
    FrDropdownGroup,
    FrDropdownOptions,
  } from "@froogal.ai/ui";
  import { useReferralProgramsStore } from "@/stores/referralPrograms";
  import { storeToRefs } from "pinia";
  import { useBrandsStore } from "@/stores/brands";
  export default {
    name: "referral-picker",
    setup() {
      const brands = useBrandsStore();
      const { selectedBrandId } = storeToRefs(brands);
      const referralsPrograms = useReferralProgramsStore();
      const { programsList, selectedProgram, selectedProgramId } = storeToRefs(referralsPrograms);
      const { fetchProgramsList, selectReferrelProgram } = referralsPrograms;
      return {
        selectedBrandId,
        programsList, 
        selectedProgram, 
        selectedProgramId,
        fetchProgramsList, 
        selectReferrelProgram
      };
    },
    components: {
      FrDropdown,
      FrDropdownGroup,
      FrDropdownHeading,
      FrDropdownOptions,
    },
    mounted() {
      if (this.selectedBrandId ) {
        this.fetchProgramsList(this.selectedBrandId);
      }
    },
    watch: {
      selectedBrandId() {
        if (this.selectedBrandId ) {
          this.fetchProgramsList(this.selectedBrandId);
        }
      },
    },
  };
  </script>
  <style>
  .referralClass:active {
    background-color: #59348b;
  }
  </style>
  