export const moduleSlug = 'referral';
export const moduleAuth = 'Referral';
import ReferralPicker from "./components/ReferralPicker.vue";
export const picker = ReferralPicker;
export const children = [
  {
    path: '',
    component: () => import('./pages/Dashboard.vue'),
    meta: {
      title: 'Referral Dashboard',
      linkTitle: 'Dashboard',
      linkIcon: 'home',
      showInNav: true,
      permission: 'Referral.Dashboard',
    }
  },
  {
    path: "program",
    meta: {
      title: "Program",
      linkTitle: "Program",
      linkIcon: "pie-chart",
      showInNav: true,
      excludeInRoute: true,
      menu: [
        {
          path: "program-analytics",
          title: "Analytics",
          permission: 'Referral.ProgramAnalytics',
        },
        {
          path: "program-reports",
          title: "Reports",
          permission: 'Referral.ProgramReports',
        },
      ],
    },
  },
  {
    path: "Goals",
    meta: {
      title: "Goals",
      linkTitle: "Goals",
      linkIcon: "line-chart",
      showInNav: true,
      excludeInRoute: true,
      menu: [
        {
          path: "goal-wise-analytics",
          title: "Analytics",
          permission: "Referral.GoalsAnalytics",
        },
        {
          path: "goal-wise-reports",
          title: "Reports",
          permission: "Referral.GoalsReports",
        },
      ],
    },
  },
  {
    path: 'program-analytics',
    component: () => import('./pages/ProgramAnalytics.vue'),
    meta: {
      title: 'Program Analytics',
      linkTitle: 'Program Analytics',
      linkIcon: 'line-chart',
      showInNav: false,
      permission: 'Referral.ProgramAnalytics',
    }
  },
  {
    path: 'goal-wise-analytics',
    component: () => import('./pages/GoalWiseAnalytics.vue'),
    meta: {
      title: 'Goal Wise Analytics',
      linkTitle: 'Goal Wise Analytics',
      linkIcon: 'bar-chart',
      showInNav: false,
      permission: "Referral.GoalsAnalytics",
    }
  },
  {
    path: 'program-reports',
    component: () => import('./pages/ProgramReports.vue'),
    meta:{  
      title: 'Program Report',
      linkTitle: 'Program',
      linkIcon: 'tasks',
      showInNav: false,
      permission: 'Referral.ProgramReports',
    }
  },
  {
    path: 'goal-wise-reports',
    component: () => import('./pages/GoalwiseReport.vue'),
    meta:{
      title: 'Goal Wise Report',
      linkTitle: 'Goal Wise',
      linkIcon: 'paper-plane-o',
      showInNav: false,
      permission: "Referral.GoalsReports",
    }
  },
  {
    path: 'referral/referrers-reports-list',
    component: () => import('./pages/ReferrersReport.vue'),
    meta: {
      title: 'Referrers Report',
      linkTitle: 'Referrers',
      linkIcon: 'users',
      showInNav: true,
      permission: "Referral.Referrers",
    }
  },    
  {
    path: 'milestone-reports-list',
    component: () => import('./pages/MilestoneReport.vue'),
    meta: {
      title: 'Milestone Report',
      linkTitle: 'Milestone',
      linkIcon: 'podcast',
      showInNav: true,
      permission: 'Referral.Milestone',
    }
  },
  {
    path: 'configurations/programs',
    component: () => import('./pages/Programs.vue'),
    meta: {
      title: 'Referral Programs',
      linkTitle: 'Configurations',
      linkIcon: 'cogs',
      showInNav: true,
      permission: 'Referral.Configurations',
    }
  },

  {
    path: 'configurations/create',
    component: () => import('./components/CreateProgram.vue'),
    meta: {
      title: 'Create Program',
      linkTitle: 'Configurations',
      linkIcon: 'cogs',
      showInNav:false,
      permission: 'Referral.Configurations.Write',
    }
  },


];

