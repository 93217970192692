import { defineStore } from 'pinia'
import { useBrandsStore } from './brands';
import { useUserProfileStore } from './user-profile';
import { initiateOAuth, handleTokenExchange } from '../authService';

export const useSessionStore = defineStore('session', {
  state: () => ({
    token: localStorage.getItem('access_token') || null,
  }),
  getters: {
    getBrands: state => state.brands,
  },
  actions: {
    async fetchToken() {
      try {
        if (this.token) {
          const user = useUserProfileStore();
          user.fetchUserProfile();

          const brands = useBrandsStore();
          brands.fetchBrands();
    
          return;
        }
      } catch (err) {
        console.error(err);
      }
      const codeParam = new URLSearchParams(window.location.search).get('code')
      if (codeParam) {
        await handleTokenExchange(codeParam)
      } else {
        initiateOAuth()
      }
    },
  },
});