import { defineStore } from 'pinia';
import { RBACclient } from "@/apiClient";
import { toast } from 'vue3-toastify';
import { urls } from '../modules/UserManagement/urls';

export const useUserProfileStore = defineStore("user", {
  state: () => ({
    user: {},
  }),
  actions: {
    async fetchUserProfile() {
      try {
        const { data } = await RBACclient.get(urls.getProfile);
        this.user = data.list;
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
      if (this.user.hasOwnProperty('is_admin')) {
        this.user.isAdmin = this.user.is_admin;
        delete this.user.is_admin;
      }
      if (this.user.hasOwnProperty('phone_number')) {
        this.user.phoneNumber = this.user.phone_number;
        delete this.user.phone_number;
      }
    },
    async updateUserProfile() {
      if (this.user.hasOwnProperty('phoneNumber')) {
        this.user.phone_number = this.user.phoneNumber;
        delete this.user.phoneNumber;
      }
      try {
        const { data } = await RBACclient.post(urls.postProfile, this.user);
        this.user = data.list;
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
      if (this.user.hasOwnProperty('is_admin')) {
        this.user.isAdmin = this.user.is_admin;
        delete this.user.is_admin;
      }
      if (this.user.hasOwnProperty('phone_number')) {
        this.user.phoneNumber = this.user.phone_number;
        delete this.user.phone_number;
      }
    },
    // async deleteUserProfile() {
    //   try {
    //     console.log("hypothetical delete profile API call should go here");
    //   } catch (error) {
    //     console.error('Error fetching data: ', error);
    //   }
    // },
    async updatePassword(passclass) {
      if (passclass.newPassword !== passclass.confirmPassword) {
        toast.error('The confirmed password does not match.', {
          autoClose: 3000,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.error('Password mismatch');
      } else if (passclass.newPassword === passclass.oldPassword) {
        toast.error('Your new password cannot be the same as your old password.', {
          autoClose: 3000,
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.error('Password similar');
      } else {
        try {
          await RBACclient.post(urls.postProfilePassword, passclass);
        } catch (error) {
          if (error.response && error.response.status === 422) {
            toast.error('Your old password does not match our records.', {
              autoClose: 3000,
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          console.error('Error fetching data: ', error);
        }
      }
    },
  },
});