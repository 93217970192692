export const moduleSlug = 'user-management';
export const moduleAuth = 'UserManagement';

export const children = [
  {
    path: "",
    redirect: to => {
      return to.path + '/' + 'managers';
    },
    meta: {
    },
  },
  {
    path : 'managers',
    component: () => import('./pages/ManagerList.vue'),
    meta: {
      title: 'Managers',
      linkTitle: 'Managers',
      linkIcon: 'users',
      showInNav: true,
      permission: 'UserManagement.Managers',
    }
  },
  {
    path : 'devices',
    component: () => import('./pages/DeviceList.vue'),
    meta: {
      title: 'Devices',
      linkTitle: 'Devices',
      linkIcon: 'laptop',
      showInNav: true,
      permission: 'UserManagement.Devices',
    }
  },
  {
    path : 'roles',
    component: () => import('./pages/RolesList.vue'),
    meta: {
      title: 'Roles',
      linkTitle: 'Roles',
      linkIcon: 'address-card',
      showInNav: true,
      permission: 'UserManagement.Roles',
    }
  },
  {
    path: 'invites',
    component: () => import('./pages/InvitesList.vue'),
    meta: {
      title: 'Invites',
      linkTitle: 'Invites',
      linkIcon: 'send',
      showInNav: true,
      permission: 'UserManagement.Invites',
    }
  },
];