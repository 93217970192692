import { createApp } from "vue";
import { createPinia } from "pinia";
import { toast } from "vue3-toastify";
import * as Sentry from "@sentry/vue";
import VueSweetlert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue3-toastify/dist/index.css";
import App from "./App.vue";
import router from "./router";
import { markRaw } from "vue";
import "@/assets/css/styles.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { tooltip } from './tooltip';
import CanvasJSChart from '@canvasjs/vue-charts';
import "@/assets/primevue.scss";
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import DataTable from "@/components/Table.vue";

import ConfirmDialog from "./components/Confirm.vue";

import {
  FrPortlet,
  FrPortletHeader,
  FrPortletHeading,
  FrPortletItem,
  FrRadioSelect,
  FrModal,
  FrPopper,
  FrMultiselect,
  FrPagination,
  FrImg,
  FrDatePicker,
  FrMerchantPicker,
  FrDateRangePicker,
  FrInsightsSimple,
  FrChartDatePicker,
  FrInsights
} from "@froogal.ai/ui"


import PrimeVue from 'primevue/config';
const app = createApp(App);
app.directive('tooltip', tooltip);
app.use(CanvasJSChart);

// froogal library components
app.component('DataTable',DataTable);
app.component('FrPortlet', FrPortlet);
app.component('FrPortletHeader', FrPortletHeader);
app.component('FrPortletHeading', FrPortletHeading);
app.component('FrPortletItem', FrPortletItem);
app.component('FrRadioSelect', FrRadioSelect);
app.component('FrModal', FrModal);
app.component('FrPopper', FrPopper);
app.component('FrMultiselect', FrMultiselect);
app.component('FrPagination', FrPagination);
app.component('FrImg', FrImg);
app.component('FrDatePicker', FrDatePicker);
app.component('FrMerchantPicker', FrMerchantPicker);
app.component('FrDateRangePicker', FrDateRangePicker);
app.component('FrInsightsSimple', FrInsightsSimple);
app.component('FrChartDatePicker', FrChartDatePicker);
app.component('FrInsights', FrInsights);
app.component('ConfirmDialog', ConfirmDialog);

// primevue Components
app.use(PrimeVue);
app.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  },
})
import { loader } from '@guolao/vue-monaco-editor'
loader.config({
  paths: {
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs',
  },
})
import { VueMonacoEditor } from '@guolao/vue-monaco-editor';
app.component('VueMonacoEditor', VueMonacoEditor);

const pinia = createPinia();
app.use(pinia);

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app.use(VueSweetlert2);

app.config.globalProperties.$toast = (message, type = "error") => {
  if (type == "success") {
    toast.success(message, {
      autoClose: 3000,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else {
    toast.error(message, {
      autoClose: 3000,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

app.config.globalProperties.$confirm = (message, confirm, cancel) => {
  return new Promise((resolve, reject) => {
    const div = document.createElement('div');
    const instance = createApp(ConfirmDialog, {
      message: message,
      confirm: confirm,
      cancel: cancel,
      onConfirm: (value) => {
        document.body.removeChild(div);
        resolve(value);
      },
    });
    instance.mount(div);
    document.body.appendChild(div);
  });
};

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://0c831981757349bab9f78cb367e5d165@o4504786425675776.ingest.us.sentry.io/4504786428231680",
    integrations: [
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
app.use(router);
app.mount("#app");
