<template>
    <fr-portlet class="d-flex flex-column align-items-center justify-content-center p-5" style="height: calc(100vh - 150px)">
      <img src="../assets/error-403.png" class="img-responsive" style="max-height: 350px;" />
      <br>
      <h4><strong>Looks like this page is forbidden</strong></h4>
      <a class="back-link" href="javascript:history.back()"><strong>Click here to go back</strong></a>
    </fr-portlet>
  </template>
  
  <script>
  import {
    FrPortlet,
  } from "@froogal.ai/ui";
  export default {
    components: {
      FrPortlet,
    }
  }
  </script>
  
  <style scoped>
  .back-link {
    color: var(--brand-purple);
    text-decoration: none;
  }
  </style>