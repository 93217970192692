<template>
    <div class="table-scroll  rounded mb-2" :style="{ 'min-height': tableHeight + 'px' }">
        <table class="table table-responsive table-hover" :class="class">
            <thead class="bg-light sticky-top" style="z-index:0" ref="tableHeader">
                <slot name="header" />
            </thead>
            <tbody v-if="!apiError && getCondition(loading, pageLoader) && dataLength" :id="bodyId">
                <slot name="body" />
            </tbody>
        </table>
        <template v-if="!apiError && !loading && dataLength == 0">
            <div class="d-flex align-items-center justify-content-center fw-bold" :style="{ 'height': tableTemplatesHeight + 'px' }">
                {{ noRecords }}
            </div>
        </template>
        <template v-if="loading && !apiError && !pageLoader">
            <div class="d-flex align-items-center justify-content-center" :style="{ 'height': tableTemplatesHeight + 'px' }">
                <i class="fa fa-spinner fa-pulse fa-3x"></i>
            </div>
        </template>
        <template v-if="apiError">
            <div class="d-flex align-items-center justify-content-center" style="background-color: rgba(0, 0, 0, 0.1);"
                :style="{ height: tableTemplatesHeight + 'px' }">
                <div class="text-center">
                    <p>Something Went Wrong</p>
                    <button class="btn" @click="reCallApi()">
                        <i class="fa fa-rotate-right text-muted fa-2x" :class="canSpin ? 'fa-spin' : ''"></i>
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'table',
    data() {
        return {
            canSpin: false,
            tableHeight: '',
            bodyId: 'table'+(Math.floor(Math.random() * 100)),
            tableTemplatesHeight: '',
        }
    },
    props: {
        class: {
            type: String,
            default: null
        },
        apiError: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: true
        },
        dataLength: {
            type: Number,
            default: 0,
        },
        noRecords: {
            type: String,
            default: 'No Data Found!'
        },
        pageLoader: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.getTable();
    },
    watch: {
        loading: function() {
            if(!this.loading) {
                this.canSpin = false;
                this.getTable();
            }      
        },
    },
    methods: {
        getTable() {
            var table = document.getElementById(this.bodyId);
            var rows = table?.rows;
            var rowHeight = rows?.length ? rows[0]?.offsetHeight : '';
            this.tableHeight = ((rowHeight ? rowHeight : 38) * 11) + 4;
            this.tableTemplatesHeight = ((rowHeight ? rowHeight : 38) * 10);
        },
        reCallApi() {
            this.canSpin = true;
            this.$emit('reCall');
        },
        getCondition(loader,pageLoader) {
            if(this.pageLoader) {
                return true;
            } else {
                  return !loader;
            }
        }
    }
}
</script>
<style>
.table {
    --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.04)!important;
}
.table-scroll .table{
    margin-bottom: 0px;
}
.table-scroll {
    overflow-y: auto;
}

.table-scroll::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 10%;
}

.table-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.table-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
}
</style>