<template>
  <div class="py-2 bg-white m-2 rounded-4">
    <div class="p-2 pt-0">
      <input type="text" placeholder="Search" class="form-control bg-light" v-model="searchBrandName" autofocus/>
    </div>
    <div class="dropdown-option-items px-2"
      :style="height ? (filteredBrands.length > 4 ? height : '') : (filteredBrands.length > 4 ? 'height:130px' : '')">
      <ul class="list-unstyled custom_setting">
        <li v-for="brand in filteredBrands" :key="brand.id" :class="brand.id == selectedBrand?.id ? 'active' : ''"
          @click="selectBrand(brand)" style="cursor: pointer">
          {{ brand.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useBrandsStore } from "@/stores/brands";
import { actionUrl } from "@/apiClient";
import { storeToRefs } from "pinia";
import Fuse from "fuse.js";
export default {
  setup() {
    const brandStore = useBrandsStore();
    const { brands } = storeToRefs(brandStore);
    return { brands };
  },
  props: ["selectedBrand", "height"],
  data() {
    return {
      searchBrandName: "",
    };
  },
  mounted() {
    if (this.brands.length === 1) {
      this.selectBrand(this.brands[0]);
    }
  },
  computed: {
    filteredBrands: function () {
      if (this.searchBrandName) {
        const fuse = new Fuse(this.brands, {
          keys: ['name'],
          threshold: 0.4, // Adjust this threshold as needed
        });
        return fuse.search(this.searchBrandName).map(result => result.item);
      }
      return this.brands;
    },
  },
  methods: {
    selectBrand(brand) {
      if(brand.uri) {
        if (this.$route.params.brand) {
        const selectedItem = this.brands.find((item) => item.uri === this.$route.params.brand);
        let url = this.$route.fullPath.replace(this.$route.params.brand, brand.uri);
          if (selectedItem) {
            window.open(url, '_blank');
          } else {
            window.open(url, '_self');
          }        
        } else {
          this.$router.replace("/" + brand.uri + "/");
        }
      } else { 
        window.location.replace(actionUrl + "business/dashboard", '_self');
        
      }
      
    },
  }
};
</script>

<style>
.dropdown-option-items {
  width: 100%;
  overflow-y: scroll;
}

.dropdown-option-items .custom_setting li {
  line-height: 2;
  padding-left: 10px;
}

.dropdown-option-items .custom_setting li.active {
  background-color: #59348b;
  border-radius: 10px;
  color: white;
}

.dropdown-option-items .custom_setting li.active:hover {
  background-color: #59348b;
  border-radius: 10px;
  color: white;
}

.dropdown-option-items .custom_setting li:hover {
  background-color: rgb(196, 196, 196);
  border-radius: 10px;
}

.dropdown-option-items::-webkit-scrollbar {
  width: 3px;
  border-radius: 10%;
}

.dropdown-option-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown-option-items::-webkit-scrollbar-thumb {
  background: #888;
}

.dropdown-option-items::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
</style>
